import clsx from "clsx";
import { forwardRef } from "react";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const DarkCard = forwardRef(function card({ children, className }: Props, ref: React.Ref<HTMLDivElement>) {
	return (
		<section ref={ref} className={clsx("px-5 py-6 lg:px-10 lg:py-12 bg-charcoal rounded-3xl text-beige", className)}>
			{children}
		</section>
	);
});

export default DarkCard;
