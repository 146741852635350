import { SessionOptions } from "iron-session";

export interface SessionData {
	username: string;
	token: string;
	isLoggedIn: boolean;
}

export const defaultSession: SessionData = {
	username: "",
	token: "",
	isLoggedIn: false,
};

export const sessionOptions: SessionOptions = {
	password: process.env.SESSION_SECRET!,
	cookieName: "ubo-session",
	cookieOptions: {
		// secure only works in `https` environments
		secure: process.env.NODE_ENV === "production",
	},
};

export async function getSession(): Promise<SessionData> {
	const response = await fetch(process.env.NEXT_PUBLIC_DOMAIN + "/api/session");
	const result = await response.json();
	return result as SessionData;
}
