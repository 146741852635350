import Link from "next/link";
import ArrowCircle from "./svgs/arrow-circle.svg";
import clsx from "clsx";
import LoadingSpinner from "./LoadingSpinner";

interface Props {
	text: string | JSX.Element;
	className?: string;
	pointDown?: boolean;
	onClick?: () => void;
	href?: string;
	isLoading?: boolean;
	arrowClasses?: string;
	reverse?: boolean;
	hideArrow?: boolean;
	scroll?: boolean;
}

/**
 * @param href - If this is passed in, the button will be a link, otherwise it will be a button
 * @param onClick - If this is passed in, the button type will be button, otherwise it will be submit. If href is passed in, this will be ignored
 */
const ButtonPanel = ({
	text,
	className,
	pointDown = false,
	onClick,
	href,
	isLoading = false,
	arrowClasses,
	reverse = false,
	hideArrow = false,
	scroll = true,
}: Props) => {
	const canClick = typeof onClick === "function";
	const commonClasses =
		"flex items-center w-full justify-between p-[0.625rem] rounded-full text-14px lg:text-base group h-[3.125rem] lg:h-[3.625rem]";
	const reverseClasses = "flex-row-reverse bg-transparent lg:pl-3.5 pr-[25px] border-1 border-charcoal text-charcoal";
	const nonReverseClasses = "lg:p-3.5 !pl-[25px] bg-charcoal-light text-beige";

	const ButtonContent = () => (
		<>
			{text}

			<div
				className={clsx(
					"grid duration-500 rounded-full w-9 h-9 place-items-center aspect-square fill-purple text-beige group-hover:text-purple",
					pointDown && "transform rotate-90",
					reverse
						? "transform rotate-180 group-hover:fill-charcoal-light group-hover:text-beige"
						: "group-hover:fill-beige"
				)}
			>
				{hideArrow ? null : isLoading ? (
					<LoadingSpinner className={clsx(arrowClasses || "w-8")} />
				) : (
					<ArrowCircle className={clsx(arrowClasses || "to-lg:w-8")} />
				)}
			</div>
		</>
	);

	if (href) {
		return (
			<Link
				href={href}
				scroll={scroll}
				className={clsx(commonClasses, reverse ? reverseClasses : nonReverseClasses, className)}
			>
				<ButtonContent />
			</Link>
		);
	} else {
		return (
			<button
				className={clsx(commonClasses, reverse ? reverseClasses : nonReverseClasses, className)}
				onClick={canClick ? onClick : undefined}
				type={canClick ? "button" : "submit"}
			>
				<ButtonContent />
			</button>
		);
	}
};

export default ButtonPanel;
