import { print } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";

import { getSession } from "./session";
import { getServerSession } from "app/actions";

// It'd be nice if these were the same method but if `getServerSession` is imported on the client the call to `cookies`
// makes it explode

export async function fetchGraphQL<TDocument = any, TVariables = any>(
	document: TypedDocumentNode<TDocument, TVariables>,
	variables?: TVariables | undefined,
	token?: string | undefined
): Promise<{ data?: TDocument; errors?: any }> {
	return fetch(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!, {
		method: "POST",
		body: JSON.stringify({
			query: print(document),
			variables: variables,
		}),
		headers: {
			"Content-Type": "application/json",
			Authorization: token ? `JWT ${token}` : "",
		},
		next: { revalidate: 0 },
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.errors) {
				// eslint-disable-next-line no-console
				console.warn(res.errors[0].message);
			}
			return {
				data: res.data,
				errors: res.errors,
			};
		})
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.warn(err);
			throw err;
		});
}

export async function clientRequest<TDocument = any, TVariables = any>(
	document: TypedDocumentNode<TDocument, TVariables>,
	variables?: TVariables
): Promise<{ data?: TDocument; errors?: any }> {
	const response = await getSession();
	const token = response.token;

	return fetchGraphQL(document, variables, token);
}

export async function serverRequest<TDocument = any, TVariables = any>(
	document: TypedDocumentNode<TDocument, TVariables>,
	variables?: TVariables
): Promise<{ data?: TDocument; errors?: any }> {
	const session = await getServerSession();
	const token = session.token;

	return fetchGraphQL(document, variables, token);
}
